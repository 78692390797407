<template>
  <div class="handle-order">
    <!-- 表单 -->
    <div class="facilityBox">
      <el-tabs v-model="activeCmpt" @tab-click="changeTab">
        <el-tab-pane label="待开发票" name="BuyersWaiting"></el-tab-pane>
        <el-tab-pane label="核对发票" name="BuyersChecked"></el-tab-pane>
        <el-tab-pane label="完成开票" name="BuyersComplete"></el-tab-pane>
      </el-tabs>
      <el-form class="queryForm" :model="manageForm" ref="manageForm">
        <div style="display: flex; flex-wrap: wrap">
          <el-form-item label="开票申请编号" prop="bsid">
            <el-input
              class="fromInp"
              v-model="manageForm.bsid"
              placeholder="请输入开票申请编号"
            ></el-input>
          </el-form-item>
          <el-form-item label="业务类型" prop="businessType">
            <el-select
              v-model="manageForm.businessType"
              placeholder="请选择业务类型"
              @change="businessChange"
              clearable
            >
              <el-option
                v-for="item in businessList"
                :key="item.Code"
                :label="item.Name"
                :value="item.Code"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="场景类型" prop="scenarioType">
            <el-select
              v-model="manageForm.scenarioType"
              placeholder="请选择场景类型"
              clearable
              @change="sceneTypeChange"
            >
              <el-option
                v-for="item in sceneList"
                :key="item.Code"
                :label="item.Name"
                :value="item.Code"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="发票类型" prop="invoiceType">
            <el-select
              v-model="manageForm.invoiceType"
              placeholder="请选择发票类型"
              clearable
            >
              <el-option
                v-for="item in invoiceList"
                :key="item.Code"
                :label="item.Name"
                :value="item.Code"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label-width="16px">
            <el-button type="primary" @click="search()" icon="el-icon-search"
              >搜索
            </el-button>
            <el-button type="danger" @click="clear()" icon="el-icon-delete"
              >清空
            </el-button>
          </el-form-item>
        </div>
        <div style="display: flex; flex-wrap: wrap">
          <el-form-item label="服务商单位" prop="supplierName">
            <el-input
              v-model="manageForm.supplierName"
              placeholder="请输入服务商单位"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="当前进度" prop="statusList">
            <el-select
              v-model="manageForm.statusList"
              placeholder="请选择当前进度"
              clearable
            >
              <el-option
                v-for="item in statusList"
                :key="item.Code"
                :label="item.Name"
                :value="item.Vals"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="支付方式" prop="PayModeID">
            <el-select
              v-model="manageForm.PayModeID"
              placeholder="请选择支付方式"
              clearable
              @change="search"
            >
              <el-option
                v-for="item in PayModeIDList"
                :key="item.Code"
                :label="item.Name"
                :value="item.Code"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="时间筛选" prop="loadingTime">
            <el-date-picker
              v-model="manageForm.loadingTime"
              type="datetimerange"
              range-separator="至"
              start-placeholder="请选择开始时间"
              end-placeholder="请选择结束时间"
              value-format="yyyy-MM-dd HH:mm:ss"
            >
            </el-date-picker>
          </el-form-item>
        </div>
      </el-form>
      <el-table
        :data="tableData"
        :header-cell-style="{ color: '#666', background: '#f0f0f0' }"
        v-loading="loading"
        :key="activeCmpt"
      >
        <el-table-column
          type="index"
          label="序号"
          width="50"
          fixed="left"
          show-overflow-tooltip
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="BSID"
          label="开票申请编号"
          width="200"
          show-overflow-tooltip
          align="center"
        >
          <template slot-scope="scope">
            <el-link
              style="color: #0f5fff; cursor: pointer"
              @click="openDetail(scope.row)"
              >{{ scope.row.BSID }}
            </el-link>
          </template>
        </el-table-column>
        <el-table-column
          prop="SupplierName"
          label="服务商单位"
          width="180"
          show-overflow-tooltip
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="InvoiceTypeName"
          label="发票类型"
          show-overflow-tooltip
          align="center"
          width="150"
        >
        </el-table-column>
        <el-table-column
          prop="BusinessTypeName"
          label="业务类型"
          show-overflow-tooltip
          align="center"
          width="150"
        >
        </el-table-column>
        <el-table-column
          prop="ScenarioTypeName"
          label="场景类型"
          show-overflow-tooltip
          align="center"
          width="150"
        >
        </el-table-column>
        <el-table-column
          prop="InvoiceKindName"
          label="开票类型"
          show-overflow-tooltip
          align="center"
          width="150"
        >
        </el-table-column>
        <el-table-column
          prop="BillCount"
          label="总计运单数量"
          show-overflow-tooltip
          align="center"
          width="150"
        >
        </el-table-column>
        <el-table-column
          prop="PRateStr"
          label="费率"
          show-overflow-tooltip
          align="center"
          width="150"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.PRateStr }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="BaseAmount"
          label="运费"
          show-overflow-tooltip
          align="center"
          width="150"
        >
          <template slot-scope="scope">
            <span style="color: #ff8b17">{{
              scope.row.BaseAmount | formatMoney
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="ExpensesOfTaxation"
          label="服务费/税费"
          show-overflow-tooltip
          align="center"
          width="150"
        >
          <template slot-scope="scope">
            <span style="color: #ff8b17">{{
              scope.row.ExpensesOfTaxation | formatMoney
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="TotalAmount"
          label="运单总金额"
          show-overflow-tooltip
          align="center"
          width="150"
        >
          <template slot-scope="scope">
            <span style="color: #ff8b17">{{
              scope.row.TotalAmount | formatMoney
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="PayModeName"
          label="支付方式"
          show-overflow-tooltip
          align="center"
          width="150"
        >
        </el-table-column>
        <el-table-column
          prop="ApplyTime"
          label="付款时间"
          width="150"
          show-overflow-tooltip
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="StatusTime"
          label="状态变更时间"
          width="150"
          show-overflow-tooltip
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="StatusName"
          label="当前进度"
          show-overflow-tooltip
          align="center"
          width="150"
        >
          <template slot-scope="scope">
            <el-tag
              type="success"
              v-if="
                scope.row.Status != 1 &&
                scope.row.Status != 2 &&
                scope.row.Status != 21
              "
              >{{ scope.row.StatusName }}
            </el-tag>
            <el-tooltip class="item" effect="dark" placement="top">
              <div slot="content">货主主动取消开票</div>
              <el-tag type="info" v-if="scope.row.Status == 1">{{
                scope.row.StatusName
              }}</el-tag>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" placement="top">
              <div slot="content" style="width: 400px">
                {{ scope.row.RefuseRemark }}
              </div>
              <el-tag type="danger" v-if="scope.row.Status == 2">{{
                scope.row.StatusName
              }}</el-tag>
            </el-tooltip>
            <el-tag type="danger" v-if="scope.row.Status == 21">{{
              scope.row.StatusName
            }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="200" fixed="right" align="center">
          <template slot-scope="scope" v-if="scope.row.Status != 5">
            <div v-if="activeCmpt == 'BuyersWaiting'">
              <el-button
                type="text"
                @click="openVoucherDialog(scope.row, 'ImportBatchID')"
                >上传凭证
              </el-button>
              <el-button
                type="text"
                @click="cancleOrder(scope.row)"
                style="color: #999"
                v-if="scope.row.Status == 0"
                >取消开票
              </el-button>
              <el-button
                type="text"
                @click="toSelectSupplier(scope.row)"
                style="color: #ff8b17"
                :loading="selectSupplierBtnloading"
                v-if="scope.row.Status == 1 || scope.row.Status == 2"
              >
                退回运单库
              </el-button>
            </div>
            <div v-if="activeCmpt != 'BuyersWaiting'">
              <el-button
                type="text"
                @click="toInvoiceDetail(scope.row)"
                style="color: #0f5fff"
                >发票详情
              </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div style="margin: 10px 0 0 0">
        <!-- 分页 -->
        <el-pagination
          class="pagination"
          background
          @current-change="handleCurrentChange"
          :current-page.sync="pagination.page"
          :page-size="pagination.pagesize"
          layout="total, prev, pager, next, jumper"
          :total="pagination.total"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog
      title="运单详情"
      :visible.sync="detailTableDialog"
      append-to-body
      v-if="detailTableDialog"
      width="1500px"
      :close-on-click-modal="false"
      class="detailTableDialog"
    >
      <el-table
        :data="detailTableList"
        :header-cell-style="{ color: '#666', background: '#f0f0f0' }"
        v-loading="detailTableLoading"
        key="detailTableList"
      >
        <el-table-column
          type="index"
          label="序号"
          width="50"
          fixed="left"
          show-overflow-tooltip
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="BBID"
          label="运单号"
          width="200"
          show-overflow-tooltip
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="ApplicantName"
          label="货主单位"
          width="180"
          show-overflow-tooltip
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="SupplierName"
          label="服务商单位"
          width="180"
          show-overflow-tooltip
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="InvoiceTypeName"
          label="发票类型"
          show-overflow-tooltip
          align="center"
          width="180"
        >
        </el-table-column>
        <el-table-column
          prop="Shipper"
          label="托运人"
          show-overflow-tooltip
          align="center"
          width="150"
        >
        </el-table-column>
        <el-table-column
          prop="ShipownerName"
          label="承运人"
          show-overflow-tooltip
          align="center"
          width="150"
        >
        </el-table-column>
        <el-table-column
          prop="ShipownerIDCard"
          label="承运人身份证号"
          show-overflow-tooltip
          align="center"
          width="180"
        >
        </el-table-column>
        <el-table-column
          prop="ShipownerPhone"
          label="承运人手机号"
          show-overflow-tooltip
          align="center"
          width="150"
        >
        </el-table-column>
        <el-table-column
          prop="PayeeName"
          label="代收人"
          show-overflow-tooltip
          align="center"
          width="150"
        >
        </el-table-column>
        <el-table-column
          prop="PayeeIDCard"
          label="代收人身份证号"
          show-overflow-tooltip
          align="center"
          width="180"
        >
        </el-table-column>
        <el-table-column
          prop="PayeePhone"
          label="代收人手机号"
          show-overflow-tooltip
          align="center"
          width="150"
        >
        </el-table-column>
        <el-table-column
          prop="ShipName"
          label="船舶名称"
          show-overflow-tooltip
          align="center"
          width="150"
        >
        </el-table-column>
        <el-table-column
          prop="GoodsName"
          label="货物名称"
          show-overflow-tooltip
          align="center"
          width="150"
        >
        </el-table-column>
        <el-table-column
          prop="GoodsUnitName"
          label="货物单位"
          show-overflow-tooltip
          align="center"
          width="150"
        >
        </el-table-column>
        <el-table-column
          prop="OriginAddress"
          label="起始地"
          show-overflow-tooltip
          align="center"
          width="200"
        >
        <template slot-scope="scope">
            <div @click="openMap(scope.row)">
              <el-link type="primary">{{  scope.row.OriginAddress}}</el-link>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="ArriveAddress"
          label="到达地"
          show-overflow-tooltip
          align="center"
          width="200"
        >
        <template slot-scope="scope">
            <div @click="openMap(scope.row)">
              <el-link type="primary">{{  scope.row.ArriveAddress}}</el-link>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="OriginTime"
          label="起始时间"
          show-overflow-tooltip
          align="center"
          width="150"
        >
        </el-table-column>
        <el-table-column
          prop="ArriveTime"
          label="到达时间"
          show-overflow-tooltip
          align="center"
          width="150"
        >
        
        </el-table-column>
        <el-table-column
          prop="Counts"
          label="货物数量"
          show-overflow-tooltip
          align="center"
          width="150"
        >
        </el-table-column>
        <el-table-column
          prop="Price"
          label="运输单价"
          show-overflow-tooltip
          align="center"
          width="150"
        >
          <template slot-scope="scope">
            <span style="color: #ff8b17">{{
              scope.row.Price | formatMoney
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="CarriageFee"
          label="运费"
          show-overflow-tooltip
          align="center"
          width="150"
        >
          <template slot-scope="scope">
            <span style="color: #ff8b17">{{
              scope.row.CarriageFee | formatMoney
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="ExpensesOfTaxation"
          label="服务费/税费"
          show-overflow-tooltip
          align="center"
          width="150"
          v-if="!currentItem.TaskTypeID.includes('DK')"
        >
          <template slot-scope="scope">
            <span style="color: #ff8b17">{{
              scope.row.ExpensesOfTaxation | formatMoney
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="TotalAmount"
          label="运单总金额"
          show-overflow-tooltip
          align="center"
          width="150"
          v-if="!currentItem.TaskTypeID.includes('DK')"
        >
          <template slot-scope="scope">
            <span style="color: #ff8b17">{{
              scope.row.TotalAmount | formatMoney
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="TrustFee"
          label="委托代开运费"
          show-overflow-tooltip
          align="center"
          width="150"
          v-if="currentItem.TaskTypeID.includes('DK')"
        >
          <template slot-scope="scope">
            <span style="color: #ff8b17">{{
              scope.row.TrustFee | formatMoney
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="ExpensesOfTaxation"
          label="委托代开服务费/税费"
          show-overflow-tooltip
          align="center"
          width="150"
          v-if="currentItem.TaskTypeID.includes('DK')"
        >
          <template slot-scope="scope">
            <span style="color: #ff8b17">{{
              scope.row.ExpensesOfTaxation | formatMoney
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="TotalAmount"
          label="委托代开运单总金额"
          show-overflow-tooltip
          align="center"
          width="150"
          v-if="currentItem.TaskTypeID.includes('DK')"
        >
          <template slot-scope="scope">
            <span style="color: #ff8b17">{{
              scope.row.TotalAmount | formatMoney
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="ThirdBillID"
          label="客户单号"
          show-overflow-tooltip
          align="center"
          width="150"
        >
        </el-table-column>
        <el-table-column
          label="操作"
          width="130"
          fixed="right"
          align="center"
          v-if="activeCmpt == 'BuyersWaiting'"
        >
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="openVoucherDialog(scope.row, 'WaybillID')"
              >上传凭证
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div style="margin: 10px 0 0 0">
        <!-- 分页 -->
        <el-pagination
          class="pagination"
          background
          @current-change="handleCurrentDetailChange"
          :current-page.sync="paginationDetail.page"
          :page-size="paginationDetail.pagesize"
          layout="total, prev, pager, next, jumper"
          :total="paginationDetail.total"
        >
        </el-pagination>
      </div>
      <div style="display: flex; align-items: center; justify-content: center">
        <el-button
          plain
          type="primary"
          size="mini"
          @click="(flag.voucherDialog = true), (voucherType = 'ImportBatchID')"
          >查看凭证</el-button
        >
        <el-button plain size="mini" type="primary" @click="downloadFile('tax')"
          >下载税务表格</el-button
        >
        <el-button
          plain
          size="mini"
          type="primary"
          @click="downloadFile('bill')"
          >下载运单表格</el-button
        >
      </div>
    </el-dialog>
    <!-- 上传凭证dialog -->
    <el-dialog
      width="1200px"
      title="凭证"
      :visible.sync="flag.voucherDialog"
      append-to-body
      v-if="flag.voucherDialog"
      :close-on-click-modal="false"
    >
      <EvidenceInfo
        :businessID="
          voucherType == 'ImportBatchID' ? currentItem.BSID : currentItem.BBID
        "
      >
      </EvidenceInfo>
    </el-dialog>

    <el-dialog width="800px" :visible.sync="traceDialog" append-to-body class="deep_dialog">
      <span slot="title">查看地图</span>
      <!-- 地图组件 -->
      <TXmap style="height: 600px" v-if="traceDialog" ref="txMap" :list="mapList"> </TXmap>
    </el-dialog>
  </div>
</template>

<script>
import TXmap from "@/components/commonCmpt/TXmap";
// 当前进度Status对应值
// 0：已支付 1：货主取消开票 2：服务商拒绝开票 10：服务商已同意 19：组合发票暂存 20：服务商已开票完成 21：货主驳回发票 22：服务商已重开发票 28：服务商发送发票到货主 29：服务商发送发票到货主/重开 100：货主确认发票
import {
  GetBTaskType,
  GetStatementList,
  GetStatementDetailList,
  CancelStatement,
  GetBStatementMenuStatus,
  FallbackStatement,
  GetPaymentMethod,
  getMapPath,
} from "@/api/purchaseManage/invoiceFlow";
import {
  DownloadTaxExcel,
  DownloadBillExcel,
} from "@/api/purchaseManage/markOut";
import { _getUserId } from "@/utils/storage";
import EvidenceInfo from "@/components/businessCmpt/evidenceInfo";
export default {
  data() {
    return {
      mapList: [],
      traceDialog: false,
      activeCmpt: "BuyersWaiting", // 切换tab
      flag: {
        voucherDialog: false, // 上传凭证弹窗
      },
      // 筛选条件数据
      manageForm: {
        bsid: "", // 开票申请编号
        supplierName: "", // 服务商单位
        businessType: "", // 业务类型
        scenarioType: "", // 场景类型
        invoiceType: "", // 发票类型
        loadingTime: ["", ""], // 时间筛选
        statusList: [],
        PayModeID: "", //支付方式
      },
      //分页控件相关参数
      pagination: {
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      },
      // 表单数据
      tableData: [],
      loading: false,
      currentItem: {},
      businessList: [], // 业务类型列表
      sceneList: [], // 场景类型列表
      invoiceList: [], // 发票类型列表
      statusList: [], // 进度列表
      detailTableDialog: false, // 结算单明细列表弹窗
      detailTableLoading: false,
      detailTableList: [],
      paginationDetail: {
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      },
      selectSupplierBtnloading: false, // 重选选择服务商按钮loading
      voucherType: "", // 上传凭证类型
      PayModeIDList: [], // 支付方式列表
    };
  },
  methods: {
    openMap(item) {
      this.flag.importListLoading = true;
      let params = {
        bbid: item.BBID,
      };
      getMapPath(params)
        .then((res) => {
          console.log(res);
          let { data } = res;
          this.mapList = data;
          this.traceDialog = true;
          console.log(  this.mapList )
        })
        .finally(() => {
          this.flag.importListLoading = false;
        });
    },
    // 切换tab
    changeTab() {
      this.$refs["manageForm"].resetFields();
      this.manageForm.statusList = [];
      this.search();
    },
    // 获取进度列表
    getBStatementMenuStatus() {
      GetBStatementMenuStatus({
        menuEnum: this.activeCmpt,
      })
        .then((res) => {
          this.statusList = res.data || [];
        })
        .finally(() => {
          this.getGoodsList();
        });
    },
    // 选择业务类型
    businessChange() {
      if (this.manageForm.businessType) {
        // 获取场景类型
        GetBTaskType({
          level: 2,
          code: this.manageForm.businessType,
        }).then((res) => {
          this.sceneList = res.data || [];
        });
      }
      this.sceneList = [];
      this.invoiceList = [];
      this.manageForm.scenarioType = "";
      this.manageForm.invoiceType = "";
    },
    // 选择场景类型
    sceneTypeChange() {
      if (this.manageForm.scenarioType) {
        // 获取发票类型
        GetBTaskType({
          level: 3,
          code: this.manageForm.scenarioType,
        }).then((res) => {
          this.invoiceList = res.data || [];
        });
      }
      this.invoiceList = [];
      this.manageForm.invoiceType = "";
    },
    // 搜索
    search() {
      this.pagination.page = 1;
      this.tableData = [];
      this.getBStatementMenuStatus();
    },
    clear() {
      this.$refs["manageForm"].resetFields();
      this.manageForm.statusList = [];
    },
    //分页控件页码change事件回调
    handleCurrentChange(e) {
      this.pagination.page = e;
      // 获取列表
      this.getGoodsList();
    },
    // 获取列表
    getGoodsList() {
      this.loading = true;
      let statusList = [];
      if (
        !this.manageForm.statusList.length ||
        this.manageForm.statusList[0] == ""
      ) {
        this.statusList.map((item) => {
          item.Vals.map((i) => {
            statusList.push(i);
          });
        });
      } else {
        statusList = JSON.parse(JSON.stringify(this.manageForm.statusList));
      }
      let params = {
        pageSize: this.pagination.pagesize,
        pageIndex: this.pagination.page,
        ...this.manageForm,
        dateStart:
          this.manageForm.loadingTime && this.manageForm.loadingTime[0]
            ? this.manageForm.loadingTime[0] + " 00:00:00"
            : "",
        dateEnd:
          this.manageForm.loadingTime && this.manageForm.loadingTime[1]
            ? this.manageForm.loadingTime[1] + " 23:59:59"
            : "",
        menuEnum: this.activeCmpt,
        statusList,
        PayModeID: this.manageForm.PayModeID,
      };
      GetStatementList(params)
        .then((res) => {
          this.tableData = res.data.DataList;
          this.pagination.total = Number(res.data.TotalCount);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 打开结算单明细列表
    openDetail(item) {
      this.currentItem = item;
      console.log("this.currentItem", this.currentItem);
      this.detailTableDialog = true;
      this.detailTableLoading = true;
      GetStatementDetailList({
        pageSize: this.paginationDetail.pagesize,
        pageIndex: this.paginationDetail.page,
        bsid: item.BSID,
      })
        .then((res) => {
          this.detailTableList = res.data.DataList;
          this.paginationDetail.total = Number(res.data.TotalCount);
        })
        .finally(() => {
          this.detailTableLoading = false;
        });
    },
    //分页控件页码change事件回调
    handleCurrentDetailChange(e) {
      this.paginationDetail.page = e;
      // 获取列表
      this.openDetail(this.currentItem.BSID);
    },
    // 取消开票
    cancleOrder(row) {
      this.$confirm("取消开票后您可以退回运单库", "取消开票", {
        confirmButtonText: "确认取消",
        cancelButtonText: "取消",
        type: "error",
      })
        .then(() => {
          const loading = this.$loading({
            lock: true,
            text: "取消中...",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
          });
          CancelStatement({
            BSID: row.BSID,
          })
            .then((res) => {
              this.search();
              this.manageForm.statusList = [];
              this.$message.success("已取消开票");
            })
            .finally(() => {
              loading.close();
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    // 退回运单库
    toSelectSupplier(row) {
      this.$confirm(
        "退回运单库后，将取消当前结算单，重新生成新的结算单。",
        "退回运单库",
        {
          confirmButtonText: "退回运单库",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          this.selectSupplierBtnloading = true;
          FallbackStatement({
            bsid: row.BSID,
          })
            .then((res) => {
              this.search();
              this.manageForm.statusList = [];
              this.$message.success(
                `退回成功，新的结算单号为${res.data.BatchID}`
              );
            })
            .finally(() => {
              this.selectSupplierBtnloading = false;
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    //打开上传凭证弹窗
    openVoucherDialog(item, type) {
      this.voucherType = type;
      this.currentItem = item;
      this.flag.voucherDialog = true;
    },
    // 查看发票详情
    toInvoiceDetail(row) {
      this.$router.push({
        path: "/purchaseManage/index/handleInvoiceDetail",
        query: {
          activeCmpt: this.activeCmpt,
          bsid: row.BSID,
          status: row.Status,
        },
      });
    },
    // 下载全部发票
    downloadFile(type) {
      let ajaxFunObj = {
        tax: DownloadTaxExcel,
        bill: DownloadBillExcel,
      };
      const loading = this.$loading({
        lock: true,
        text: "下载中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      ajaxFunObj[type](
        {
          bsid: this.currentItem.BSID,
          applicantUserID: parseInt(_getUserId()) / 5,
        },
        {
          responseType: "blob",
        }
      ).catch((err) => {
        let { data } = err;
        let that = this;
        if (err.type == "application/json") {
          let reader = new FileReader();
          reader.readAsText(err);
          reader.onload = function (e) {
            const { msg } = JSON.parse(reader.result);
            that.$message({
              message: `下载失败 ${msg}`,
              type: "error",
            });
          };
          return;
        }

        const blob = new Blob([data]);
        let fileName = err.headers["content-disposition"]
          .split(";")[1]
          .split("=")[1]; //拆解获取文件名，
        if ("download" in document.createElement("a")) {
          // 非IE下载
          const elink = document.createElement("a");
          elink.download = fileName;
          elink.style.display = "none";
          elink.href = URL.createObjectURL(blob);
          document.body.appendChild(elink);
          elink.click();
          URL.revokeObjectURL(elink.href); // 释放URL 对象
          document.body.removeChild(elink);
        } else {
          // IE10+下载
          navigator.msSaveBlob(blob, fileName);
        }
        loading.close();
      });
    },
  },
  created() {
    this.activeCmpt = this.$route.query.activeCmpt
      ? this.$route.query.activeCmpt
      : "BuyersWaiting";
    this.getBStatementMenuStatus();
    // 获取业务类型
    GetBTaskType({
      level: 1,
      code: "",
    }).then((res) => {
      this.businessList = res.data || [];
    });

    GetPaymentMethod({
      bTaskTypeCode: "1",
    }).then((res) => {
      this.PayModeIDList = res.data || [];
    });
  },
  components: {
    EvidenceInfo,
    TXmap
  },
};
</script>
<style lang="scss">
.handle-order {
  .queryForm {
    display: block;

    .el-form-item {
      display: flex;
      align-items: center;

      .el-form-item__label {
        width: 100px;
      }

      .el-form-item__content {
        width: 200px;
      }
    }
  }

  .el-dialog__footer {
    text-align: center;
  }
}

.detailTableDialog {
  .el-dialog {
    margin-top: 9vh !important;
  }
}
</style>
